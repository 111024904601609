import React, { Fragment } from 'react';
import './App.scss';
import { Portfolio } from './components/portfolio';

function App() {
  return (
    <Fragment>
      <header className="header">
        <nav>
          <ul>
            <li><a href="">Home</a></li>
            <li><a href="#portfolio">Projects</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>
      <div className='background'></div>
      <main>
        <section id="about">
        <h1>Yokna</h1>
        Yokna was born out of our founding team's experience in quickly bringing digital products to the market for startups all across the world. We have assembled a team of multidisciplinary experts who can architect and deliver full-stack solutions.
        </section>
        <Portfolio></Portfolio>
        <section id="contact">
          <h2>Contact</h2>
          <div>
            feel free to write us at<br/>
            <a href='mailto:info@yokna.ltd'>info@yokna.ltd</a>
          </div>
        </section>
      </main>
      <footer>
        <div className='copyright'>© Yokna ltd. 2023</div>
        <a href="https://www.flaticon.com/free-icons/app" title="app icons" target="_blank">App icons created by Freepik - Flaticon</a>
      </footer>
    </Fragment>
  );
}

export default App;
