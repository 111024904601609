
interface Project {
    urls: (string | {url: string, icon: string})[];
    name: string;
    description: string;
    photo?: string;
}

const projects: Project[] = [
    {
        urls: ["https://www.teylor.com/en/"],
        name: "Tеylor",
        description: "Teylor is a next generation SME lending platform that has served thousands of businesses in Western and Central Europe since 2019.",
        photo: "./img/tеylor.jpg"
    },
    {
        urls: ["https://www.enacttrust.com/"],
        name: "EnactТrust",
        description: "EnactTrust is IoT cybersecurity software that consists of a firmware agent and a suite of cloud services.",
        photo: "./img/enacttrust.jpg"
    },
    {
        urls: [
            {url: "https://apps.apple.com/bg/app/airlief-air-quality-data-tips/id1372607818", icon: "./img/app-store.png"},
            {url: "https://play.google.com/store/apps/details?id=airlief.airlief", icon: "./img/play-store.png"},
        ],
        name: "Airlief app",
        description: "An iOS and Android mobile app for real time air quality tracking. Developed for a Bulgarian startup, it features real-time location services and air quality data, a map with nearby sensors, medical health tips and recommendations, personalization, and much more. Airlief was #1 in the the Weather category in both the App Store and Play Store for multiple consecutive months.",
        photo: "./img/airlief.jpg"
    },
    {
        urls: ["https://onon.productions"],
        name: "On&on Productions",
        description: "A showcase website for a production house featuring video reels, accompanying images for each project, rental catalogue page, and other informational pages.",
        photo: "./img/onon.jpg"
    },
    {
        urls: ["https://novoselski.com"],
        name: "Novoselski.com",
        description: "A personal portfolio site for the photographer Aleksander Novoselski. Features clean minimalistic design with emphasis on the visual content.",
        photo: "./img/novoselski.jpg"
    },
    {
        urls: ["https://solidaritymachine.space/#/exhibitions"],
        name: "Solidarity Machine",
        description: "OpenAI integration for prompt completion, server, and website as a part of an experimental conceptual artwork by Albena Baeva and Gergana Baeva.",
        photo: "./img/solidaritymachine.jpg"
    }
];

export function Portfolio() {

    return (
        <section id="portfolio">
            <h2>Projects</h2>
            {
                projects.map(x => {
                    return (<div className="project">
                            <img className="project-image" src={x.photo} />
                            <h3>
                                {
                                    (() => {

                                        if(typeof(x.urls[0]) === "string"){
                                            return (<a href={x.urls[0]} target="_blank">{x.name}</a>);
                                        }
                                        else {

                                            return x.name;
                                        }
                                    })()
                                }
                            </h3>
                            <div className="links">
                                {
                                    x.urls.map(u => {
                                        if (typeof(u) === "string") {
                                            return null;
                                        } else {
                                            return (<a href={u.url} target="_blank"><img src={u.icon} /></a>);
                                        }
                                    })
                                }
                            </div>
                            <div className="description">
                                {x.description}
                            </div>
                        </div>);
                })
            }
        </section>
    )
}